import React, { useEffect } from "react";
import "./ContactStyle.scss";

declare global {
  interface Window {
    kakao: any;
  }
}

const Contact: React.FC = () => {
  const kakaoMaps = window.kakao?.maps || undefined;

  useEffect(() => {
    if (kakaoMaps) {
      let container = document.getElementById("map");

      let options = {
        center: new kakaoMaps.LatLng(37.550566, 126.909755),
        level: 3,
      };

      let map = new kakaoMaps.Map(container, options);

      let geocoder = new kakaoMaps.services.Geocoder();

      geocoder.addressSearch(
        "서울특별시 성동구 연무장15길 11 에스팩토리 B동, 2층",
        function (result: any, status: any) {
          if (status === window.kakao.maps.services.Status.OK) {
            let coords = new kakaoMaps.LatLng(result[0].y, result[0].x);
            // 결과값으로 받은 위치를 마커로 표시
            let marker = new kakaoMaps.Marker({
              map: map,
              position: coords,
            });

            let infowindow = new kakaoMaps.InfoWindow({
              content:
                '<div style="width:150px;text-align:center;padding:6px 0;">팜에어</div>',
            });
            infowindow.open(map, marker);

            map.setCenter(coords);
          }
        }
      );
    }
  }, [kakaoMaps]);

  return (
    <div
      className="Contact"
      id="CONTACT"
      style={{ width: "100vw", height: "100vh" }}>
      <div className="Contact container-fluid h-100">
        <div className="row align-items-center h-100 justify-content-md-center">
          <div className="Contact__text col-lg-8 col-12">
            <h1 className="title">CONTACT</h1>
            <div className="Contact_box col-lg-6">
              <div className="Contact__location col-12 col-lg-12">
                <h1>LOCATION</h1>
                <ul>
                  <li>
                    우) 04783 서울특별시 성동구 연무장15길 11 에스팩토리 B동,
                    2층
                  </li>
                  <li>성수역 3번 출구에서 도보로 3분 거리</li>
                </ul>
              </div>
              <div className="Contact__contactText col-12 col-lg-12">
                <h1>CONTACT</h1>
                <ul>
                  <li>
                    <p>TEL</p>
                    <span>02 6952 5225</span>
                  </li>
                  <li>
                    <p>FAX</p>
                    <span>070 8228 6858</span>
                  </li>
                  <li>
                    <p>E-MAIL</p>
                    <span>rokya001@gmail.com</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="Contact_img col-12 col-lg-6" id="map"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
