import React, { Component } from "react";
import ServiceTemplate from "./ServiceTemplate";
import "./ServiceStyle.scss";

class Service extends Component {
  render() {
    return (
      <div className="service_section" id="SERVICE">
        <div className="row align-items-center justify-content-md-center">
          <div className="service_terran col-lg-9 col-12">
            <ServiceTemplate />
          </div>
        </div>
      </div>
    );
  }
}

export default Service;
