import React from "react";
import "./App.css";
import Main from "./Components/Main/Main";
import Header from "./Components/Header/Header";
import About from "./Components/About/About";
import Service from "./Components/Service/Service";
import Contact from "./Components/Contact/Contact";
import BottomInfo from "./Components/BottomInfo/BottomInfo";
import Client from "./Components/Client/Client";

const App: React.FC = () => {
  console.log(2);
  return (
    <div className="App">
      <Header />
      <Main />
      <About />
      <Service />

      <Client />

      <Contact />

      <BottomInfo />
    </div>
  );
};

export default App;
