import React from "react";
import "./ClientStyle.scss";

const Client: React.FC = () => {
  return (
    <div className="client_section" id="CLIENT">
      <div className="client_text">
        <h1 className="client_title">OUR CLIENT</h1>
      </div>

      <div className="client_container col-lg-7">
        <div className="client_box">
          <img src="static/asset/client/emart.png" alt="emart_ci" />
        </div>
        <div className="client_box">
          <img src="static/asset/client/cj_freshway.png" alt="cj_freshway" />
        </div>
        <div className="client_box">
          <img
            src="static/asset/client/hyundai_department.png"
            alt="hyundai_department"
          />
        </div>
        <div className="client_box">
          <img
            src="static/asset/client/hyundai_greenfood.png"
            alt="hyundai_greenfood"
          />
        </div>
        <div className="client_box">
          <img src="static/asset/client/lottemart.png" alt="lottemart" />
        </div>

        <div className="client_box">
          <img src="static/asset/client/ourhome.png" alt="ourhome" />
        </div>

        <div className="client_box">
          <img src="static/asset/client/meatboss.png" alt="meatboss" />
        </div>

        <div className="client_box">
          <img src="static/asset/client/gangwon.png" alt="meatboss" />
        </div>
      </div>
    </div>
  );
};

export default Client;
