import React, { useEffect, useState } from "react";
import "./MainStyle.scss";

function Main() {
  const [, setLoadComlete] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoadComlete(true), 500);
  }, []);
  return (
    <div className="Main">
      <div className="Main container-fluid">
        <div className="row">
          <div className="col-12 col-lg-12 Main__background">
            <h1>
              <span className="company__title">
                FARMAIR
                <br />
              </span>
              <span className="desc1">
                첨단농업의 시작
                <br />
              </span>
              <span className="desc1__bar">
                <br />
              </span>
              <span className="desc2">
                정보기술을 기반으로 농업을 재해석 하며
                <br />
                농업의 지속 가능한 발전을 추구합니다.
              </span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
